<template>
    <div
        :class="[
            'relative',
            'sm:rounded-lg',
            'shadow dark:shadow-none',
            'border-b border-gray-200 dark:border-slate-500',
            'bg-white dark:bg-gray-800/50',
            'dark:bg-gradient-to-bl dark:from-slate-600/50 dark:via-transparent',
            'ring-1 ring-gray-200 dark:ring-slate-700',
            'hover:ring-gray-400/40 dark:hover:ring-slate-500',
            'transition-shadow duration-300',
        ]"
    >
        <slot />
    </div>
</template>
