import { DateTime } from 'luxon'
import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import * as Sentry from '@sentry/vue'

export const useAuthStore = defineStore('auth', {
    persist: {
        key: 'auth',
    },

    state: () => ({
        user: <Api.ApiUserResponse | null>null,
        token: getCookie('token') ?? null,
        expires_in: <string | null>null,
        updated_at: <string | null>null,
    }),

    getters: {
        check: (state) => Boolean(state.user?.id ?? false),
        admin: (state) => Boolean(state.user?.admin ?? false),
        lista: (state) => state.user?.lista || { id: 0 },
        margenCristacolor: (state) => state.user?.margen_cristacolor || 1,
        coeficienteLista: (state) => state.user?.lista?.coeficiente || 1,
        margen(): number {
            return Math.round(this.margenCristacolor * this.coeficienteLista * 1000) / 1000
        },
    },

    actions: {
        async getPrecios() {
            if (this.updated_at) {
                const diff = DateTime.now().diff(DateTime.fromISO(this.updated_at), 'hours').toObject()

                if (diff && diff.hours && diff.hours >= 24) {
                    await this.syncLista()
                }
            }
        },

        async syncLista() {
            if (useCoreStore().isOnline) {
                await this.fetchUser()
            }
        },

        async setNewCsrfCookie() {
            console.info('getting csrf cookie')

            await window.axios.get('/sanctum/csrf-cookie')
        },

        getCsrfCookie() {
            return getCookie('XSRF-TOKEN') ?? null
        },

        saveToken({ access_token, expires_in }: Api.ApiLoginResponse) {
            this.token = access_token
            this.expires_in = expires_in

            this.setToken()
        },

        setToken() {
            if (this.expires_in) {
                setCookie('token', this.token, {
                    expires: DateTime.fromISO(this.expires_in).toJSDate(),
                    sameSite: 'lax',
                    path: '/',
                })
            }

            window.axios.defaults.headers.Authorization = `Bearer ${this.token}`
        },

        removeToken() {
            removeCookie('token', {
                path: '/',
            })

            this.$reset()
        },

        async fetchUser() {
            await window.axios.get<Api.ApiUserResponse>('/api/user').then((response) => {
                if (response.data.photo_url) {
                    response.data.photo_url = `${import.meta.env.VITE_API_URL}/${response.data.photo_url}`
                }

                this.updateUser(response.data)
            })
        },

        updateUser(user: Api.ApiUserResponse) {
            this.user = user

            this.updated_at = DateTime.now().toISO()

            Sentry.setUser({
                id: String(this.user.id),
                username: String(this.user.name),
                email: String(this.user.email),
            })
        },

        async saveMargen(margen: number | null) {
            const updatedMargen = margen == 0 ? null : margen

            await window.axios
                .put('/api/cristacolor/update/margen', {
                    margen_cristacolor: updatedMargen,
                })
                .then(() => {
                    this.fetchUser()
                })
                .catch((error) => {
                    Sentry.captureException(error)
                })
        },

        async login(username: string, password: string) {
            const { routerPush } = useRouterPush()

            if (this.token) {
                routerPush('Dashboard')

                return
            }

            await window.axios
                .post<Api.ApiLoginResponse>('/api/login', {
                    username,
                    password,
                })
                .then(async (response) => {
                    this.saveToken(response.data)

                    await this.fetchUser()

                    routerPush('Dashboard')
                })
        },

        async logout() {
            await window.axios.post('/api/logout').then(() => {
                this.logoutCleaner()
            })
        },

        logoutCleaner() {
            const { routerPush } = useRouterPush()

            Sentry.setUser(null)

            this.removeToken()

            window.axios.defaults.headers.Authorization = null

            console.info('auth store cleaned')

            routerPush('Login')
        },
    },

    share: {
        enable: true,
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
