import { useRegisterSW } from 'virtual:pwa-register/vue'

export const usePwaStore = defineStore('pwa', () => {
    const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
        onRegisteredSW(swScriptUrl, registration) {
            console.log('Service worker has been registered.')

            if (registration) {
                setInterval(() => {
                    console.log('Checking for service worker update.')

                    registration.update()
                }, 3_600_000 /* 1 hora */)
            }
        },
    })

    async function updatePwa(): Promise<void> {
        await updateServiceWorker()
    }

    return {
        offlineReady,
        needRefresh,
        updatePwa,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePwaStore, import.meta.hot))
}
