const roundingOptions = [
    { id: 1, value: 100, desc: 'múltiplo de 100' },
    { id: 2, value: 10, desc: 'múltiplo de 10' },
    { id: 3, value: 1, desc: 'sin decimales' },
    { id: 4, value: 0.1, desc: '1 decimal' },
    { id: 5, value: 0.01, desc: 'sin redondeo' },
] as const

export const useCoreStore = defineStore('core', {
    state: () => ({
        version: process.env.APP_VERSION || 'DEVELOPMENT',
        darkMode: useDark(),
        isOnline: useOnline(),
        roundType: useStorage<(typeof roundingOptions)[number]['id']>('round-type', roundingOptions[1].id),
        appMounted: false,
        coreLoaded: false,

        colores: <App.Models.ColorReduced[]>[],
        productos: <App.Models.Producto[]>[],
        lineas: <App.Models.Linea[]>[],
        envases: <App.Models.Envase[]>[],
        bases: <App.Models.Base[]>[],
        tintas: <App.Models.TintaReduced[]>[],
        swatches: <App.Models.Swatch[]>[],
        possibilityMatrix: <App.Models.PossibilityMatrixReduced[]>[],
        coloresFormula: <App.Models.ColorFormulaReduced[]>[],
        productosBasesFactores: <App.Models.ProductoBaseFactor[]>[],
        productosPrecios: <App.Models.ProductoPrecioReduced[]>[],
    }),

    getters: {
        isDark: (state) => state.darkMode,
        roundingOptions: () => roundingOptions,
        round: (state) => roundingOptions.find((option) => option.id == state.roundType)?.value || roundingOptions[0].value,
        dataTableOperators: (): readonly DataTable.Operators[] =>
            [
                { operator: 'like', description: 'Contiene' },
                { operator: '=', description: 'Igual que' },
                { operator: '!=', description: 'No es igual que' },
            ] as const,
        dataTableOperatorsExpanded(): readonly DataTable.Operators[] {
            return [
                ...this.dataTableOperators,
                { operator: '>', description: 'Mayor que' },
                { operator: '<', description: 'Menor que' },
                { operator: '>=', description: 'Mayor o igual que' },
                { operator: '<=', description: 'Menor o igual que' },
            ] as const
        },
    },

    actions: {
        setAppMounted() {
            this.appMounted = true
        },

        toggleDark() {
            this.darkMode = !this.darkMode
        },

        async fetchCoreData() {
            if (this.coreLoaded) {
                return
            }

            this.appMounted = false
            this.coreLoaded = false

            await this.loadDynamicModules().then(() => {
                this.coreLoaded = true

                this.setAppMounted()
            })
        },

        async loadDynamicModules() {
            Promise.all([
                import('@/assets/json/colores').then((m) => m['colores'] || m),
                import('@/assets/json/productos').then((m) => m['productos'] || m),
                import('@/assets/json/lineas').then((m) => m['lineas'] || m),
                import('@/assets/json/envases').then((m) => m['envases'] || m),
                import('@/assets/json/bases').then((m) => m['bases'] || m),
                import('@/assets/json/tintas').then((m) => m['tintas'] || m),
                import('@/assets/json/swatches').then((m) => m['swatches'] || m),
                import('@/assets/json/possibilityMatrix').then((m) => m['possibilityMatrix'] || m),
                import('@/assets/json/coloresFormula').then((m) => m['coloresFormula'] || m),
                import('@/assets/json/productosBasesFactores').then((m) => m['productosBasesFactores'] || m),
                import('@/assets/json/productosPrecios').then((m) => m['productosPrecios'] || m),
            ]).then((response) => {
                this.colores = response[0]
                this.productos = response[1]
                this.lineas = response[2]
                this.envases = response[3]
                this.bases = response[4]
                this.tintas = response[5]
                this.swatches = response[6]
                this.possibilityMatrix = response[7]
                this.coloresFormula = response[8]
                this.productosBasesFactores = response[9]
                this.productosPrecios = response[10]
            })
        },
    },

    share: {
        enable: true,
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCoreStore, import.meta.hot))
}
