import type { RouteRecordRaw } from 'vue-router'

import authMiddleware from '@/router/middleware/authMiddleware'
import guestMiddleware from '@/router/middleware/guestMiddleware'

export default <RouteRecordRaw[]>[
    /* ------------ */
    /* Start Layout */
    /* ------------ */
    {
        path: '/',
        component: () => import('@/layouts/StartLayout.vue'),
        meta: { middleware: [guestMiddleware] },
        children: [
            { path: '/', name: 'Inicio', component: () => import('@/pages/WelcomePage.vue') },
            /* ------------ */
            /* Login Routes */
            /* ------------ */
            { path: '/login', name: 'Login', component: () => import('@/pages/auth/LoginPage.vue') },
        ],
    },
    /* ------------- */
    /*  Main Layout  */
    /* ------------- */
    {
        path: '/app',
        component: () => import('@/layouts/MainLayout.vue'),
        meta: { middleware: [authMiddleware] },
        children: [
            { path: '/experimental', name: 'Experimental', component: () => import('@/pages/TestPage.vue') },
            { path: '/dashboard', name: 'Dashboard', component: () => import('@/pages/DashboardPage.vue') },
            { path: '/rendimiento', name: 'Rendimiento', component: () => import('@/pages/rendimiento/RendimientoPage.vue') },
            { path: '/simular', name: 'Simular', component: () => import('@/pages/simular/SimularPage.vue') },
            { path: '/docs/formular', name: 'HowToFormular', component: () => import('@/pages/docs/HowToFormular.vue') },
            { path: '/config', name: 'Config', component: () => import('@/pages/config/ConfigPage.vue') },
        ],
    },
    /* Error Routes */
    {
        path: '/error',
        component: () => import('@/layouts/StartLayout.vue'),
        children: [{ path: '/:pathMatch(.*)*', name: '404', component: () => import('@/pages/errors/ErrorPage404.vue') }],
    },
]
