<script setup lang="ts">
    const show = ref(true)

    const pwaSore = usePwaStore()

    watch(
        () => pwaSore.needRefresh,
        (value, oldValue) => {
            if (value && value != oldValue) {
                show.value = true
            }
        },
    )

    function close() {
        show.value = false

        pwaSore.offlineReady = false
    }
</script>

<template>
    <div v-if="show && (pwaSore.offlineReady || pwaSore.needRefresh)" class="fixed bottom-0 right-0 z-20 p-8" role="alert">
        <a-card class="dark:bg-slate-800">
            <a-card-body>
                <div>
                    <template v-if="pwaSore.offlineReady"> App lista para trabajar sin conexión </template>

                    <template v-else> Actualización disponible, haga clic en el botón "<span class="font-semibold">Actualizar</span>". </template>
                </div>

                <div class="flex items-center space-x-2">
                    <a-button v-if="pwaSore.needRefresh" color="green" @click="pwaSore.updatePwa"> Actualizar </a-button>

                    <a-button @click="close"> <span class="font-semibold">Cerrar</span> </a-button>
                </div>
            </a-card-body>
        </a-card>
    </div>
</template>
